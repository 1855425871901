export enum ComplianceStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

// A mapping of enum values to display values
const statusDisplayMap: { [key in ComplianceStatus]: string } = {
  [ComplianceStatus.NotStarted]: 'To do',
  [ComplianceStatus.InProgress]: 'In Progress',
  [ComplianceStatus.Completed]: 'Completed',
};

export const ComplianceStatusUtil = {
  getDisplayName(status: string) {
    return statusDisplayMap[this.parseStatus(status)];
  },
  parseStatus(status: string) {
    for (const value of Object.values(ComplianceStatus)) {
      if (status === value) {
        return value;
      }
    }
    return ComplianceStatus.NotStarted;
  },

  getStatusColor(status: string) {
    switch (status) {
      case ComplianceStatus.NotStarted:
        return;
      case ComplianceStatus.InProgress:
        return 'yellow';
      case ComplianceStatus.Completed:
        return 'green';
    }
  },
};
