export enum SiteVisitStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

// A mapping of enum values to display values
const statusDisplayMap: { [key in SiteVisitStatus]: string } = {
  [SiteVisitStatus.NotStarted]: 'To do',
  [SiteVisitStatus.InProgress]: 'In Progress',
  [SiteVisitStatus.Completed]: 'Completed',
};

export const SiteVisitUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(SiteVisitStatus)) {
      if (status === value) {
        return value;
      }
    }
    return SiteVisitStatus.NotStarted;
  },

  getDisplayName(status: string) {
    return statusDisplayMap[this.parseStatus(status)];
  },

  getStatusColor(status: string) {
    switch (status) {
      case SiteVisitStatus.NotStarted:
        return;
      case SiteVisitStatus.InProgress:
        return 'yellow';
      case SiteVisitStatus.Completed:
        return 'green';
    }
  },
};
