import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import { createDeepEqualSelector } from 'store/utils.ts';
import { IPermissionEntity } from 'models/Permission.model';

// Entity Adapter
const entityAdapter = createEntityAdapter<IPermissionEntity>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

// Actions
const upsertPermissions = createAction<IPermissionEntity[]>('domainData/permission/upsertPermissions');

// Thunks
export const fetchPermissions = createAsyncThunk('domainData/permission/fetchPermissions', async (_, thunkAPI) => {
  const permissionService = serviceContainer.cradle.permissionService;
  const { permissions } = await permissionService.fetchPermissions();
  thunkAPI.dispatch(upsertPermissions(permissions));
  return permissions;
});

// Reducer
const initialState = entityAdapter.getInitialState();

export const permissionReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertPermissions, entityAdapter.upsertMany);
});

// Selectors
export const {
  selectById: selectPermissionEntityById,
  selectIds: selectPermissionEntityIds,
  selectEntities: selectPermissionEntities,
  selectAll: selectAllPermissionEntities,
  selectTotal: selectTotalPermissionEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.permission);

export const selectPermissionsByResourceType = createDeepEqualSelector(
  [selectAllPermissionEntities, (state: RootState, resourceType: string) => resourceType],
  (permissions, resourceType) => {
    return permissions.filter((permission) => permission.resourceType === resourceType);
  },
);

export const selectAllPermissionNames = createDeepEqualSelector([selectAllPermissionEntities], (permissions) => {
  return permissions.map((permission) => permission.name);
});

export const selectPermissionNamesByResourceType = createDeepEqualSelector(
  [selectPermissionsByResourceType],
  (permissions) => {
    return permissions.map((permission) => permission.name);
  },
);
