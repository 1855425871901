import {
  ConfirmResetPasswordArgs,
  ICognitoService,
  LoginArgs,
  LoginWithRedirectArgs,
  ResetPasswordArgs,
} from 'services/cognito/CognitoService.types';
import trim from 'lodash/trim';
import toString from 'lodash/toString';
import { Cradle } from 'services/serviceContainer.types';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import { confirmResetPassword, resetPassword, signIn, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import {
  AWS_COGNITO_OAUTH_BASE_URL,
  AWS_COGNITO_OAUTH_DOMAIN,
  AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID,
} from 'constants/configs';
import { ICognitoUser } from 'models/CognitoUser.model';

export class CognitoService implements ICognitoService {
  private readonly logger: Cradle['logger'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: Pick<Cradle, 'logger' | 'i18n'>) {
    this.logger = args.logger;
    this.i18n = args.i18n;
  }

  public initAWSAmplify() {
    const config: ResourcesConfig = {
      Auth: {
        Cognito: {
          userPoolClientId: `${AWS_USER_POOLS_WEB_CLIENT_ID}`,
          userPoolId: `${AWS_USER_POOLS_ID}`,
          signUpVerificationMethod: 'code',
          loginWith: {
            oauth: {
              domain: `${AWS_COGNITO_OAUTH_DOMAIN}`,
              scopes: ['openid', 'profile', 'email'],
              redirectSignIn: [`${AWS_COGNITO_OAUTH_BASE_URL}signedin`],
              redirectSignOut: [`${AWS_COGNITO_OAUTH_BASE_URL}signedout`],
              responseType: 'code',
            },
          },
        },
      },
    };
    Amplify.configure(config);
  }

  public async getIdToken(): Promise<string> {
    return await fetchAuthSession()
      .then(({ tokens }) => {
        return toString(tokens?.idToken);
      })
      .catch((error) => {
        this.logger.error('error getting id token: ', error);
        throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
      });
  }

  public async signInWithCredentials(args: LoginArgs) {
    const { username, password } = args;
    try {
      await signIn({ username, password });
    } catch (error: any) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
    }
    const cognitoUser = await this.signInWithCachedState();
    return cognitoUser;
  }

  public async signInWithRedirectUrl(args: LoginWithRedirectArgs) {
    return await signInWithRedirect({ provider: { custom: args.domain }, customState: args.targetPage }).catch(
      (error) => {
        this.logger.error('error login with redirectUrl: ', error);
      },
    );
  }

  public async signOut(): Promise<void> {
    await signOut().catch((error) => {
      this.logger.error('error signing out: ', error);
    });
  }

  public async signInWithCachedState(): Promise<ICognitoUser> {
    let authSessionDetails: any;
    await fetchAuthSession()
      .then((session) => (authSessionDetails = session.tokens?.idToken))
      .catch((error) => {
        this.logger.error('Error fetching current auth session: ', error.message);
      });

    // Early return if there is no auth session
    if (!authSessionDetails) {
      return Promise.reject(new ServiceError('Error fetching current auth session'));
    }

    let currentUserDetails: any;

    await getCurrentUser()
      .then((userDetails) => (currentUserDetails = userDetails))
      .catch((error) => {
        this.logger.error('Error getting current user: ', error.message);
      });

    if (!currentUserDetails) {
      return Promise.reject(new ServiceError('Error fetching current user'));
    }

    const cognitoUser = {
      username: toString(currentUserDetails?.username),
      userEmail: toString(authSessionDetails.payload.email),
      emailVerified: Boolean(authSessionDetails.payload.email_verified),
      federatedLogin: Boolean(''),
      userUniqueId: toString(currentUserDetails?.userId),
      userFirstName: toString(authSessionDetails.payload.given_name),
      userLastName: toString(authSessionDetails.payload.family_name),
      userPermissions: [],
    };
    return cognitoUser;
  }

  public async resetPassword(args: ResetPasswordArgs): Promise<void> {
    return await resetPassword({ username: args.username })
      .then(() => {})
      .catch((error: any) => {
        this.logger.error('error getting resetPassword: ', error.message);
        throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
      });
  }

  public async confirmResetPassword(args: ConfirmResetPasswordArgs): Promise<void> {
    return await confirmResetPassword({
      username: args.username,
      confirmationCode: args.confirmationCode,
      newPassword: args.newPassword,
    });
  }

  private getLocalizedErrorMessage(error: Error): string {
    const errorMessage = trim(error?.message || '', '.');
    const localizedErrorMessage = this.i18n.t(errorMessage);
    return localizedErrorMessage;
  }
}
