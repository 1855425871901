import { z } from 'zod';
import toString from 'lodash/toString';
import { toArray } from 'lodash';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

export enum RoleStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Unknown = 'Unknown',
}

export enum RoleTab {
  Role = 'Role',
  Users = 'Users',
}

export const roleSchema = z.object({
  id: z.string(),
  revision: z.number(),
  name: z.string(),
  description: z.string(),
  status: z.nativeEnum(RoleStatus),
  userIds: z.string().array().optional(),
  councilCode: z.string().optional(),
  activeDirectoryGroupId: z.string().optional(),
  activeDirectoryGroupName: z.string().optional(),
  privileges: z.string().array().optional(),
});

export type IRoleEntity = z.infer<typeof roleSchema>;
export type AddRoleArgs = Omit<IRoleEntity, 'id' | 'users'>;

export const RolesUtil = {
  parseRoles(json: any[]): IRoleEntity[] {
    const roles: IRoleEntity[] = [];
    for (const role of json) {
      roles.push({
        id: toString(get(role, 'id')),
        revision: toNumber(get(role, 'revision')),
        name: toString(get(role, 'name')),
        description: toString(get(role, 'description')),
        status: RolesUtil.parseStatus(toString(get(role, 'status'))),
        userIds: toArray(get(role, 'userIds')),
        councilCode: toString(get(role, 'councilCode')),
        privileges: toArray(get(role, 'privileges')),
      });
    }
    return roles;
  },
  parseRole(json: any): IRoleEntity {
    const role = {
      id: toString(get(json, 'id')),
      name: toString(get(json, 'name')),
      userIds: toArray(get(json, 'userIds')),
      status: RolesUtil.parseStatus(toString(get(json, 'statusDetails.status'))),
      revision: toNumber(get(json, 'revision')),
      description: toString(get(json, 'description')),
      councilCode: toString(get(json, 'councilCode')),
      activeDirectoryGroupId: toString(get(json, 'activeDirectoryGroupId')),
      activeDirectoryGroupName: toString(get(json, 'activeDirectoryGroupName')),
      privileges: toArray(get(json, 'privileges')),
    };
    return role;
  },
  parseStatus(status: string) {
    for (const value of Object.values(RoleStatus)) {
      if (status === value) {
        return value;
      }
    }
    return RoleStatus.Unknown;
  },

  getDefaultPermissions(role?: IRoleEntity) {
    if (role) {
      return toArray(role.privileges);
    }
    return [];
  },

  initFormValues(): AddRoleFormValues {
    return {
      name: '',
      status: RoleStatus.Enabled,
      privileges: this.getDefaultPermissions(),
      description: '',
    };
  },

  convertToFormValues(role: IRoleEntity): UpdateRoleFormValues {
    return {
      id: toString(role.id),
      revision: toNumber(role.revision),
      name: toString(role.name),
      status: toString(role.status),
      privileges: this.getDefaultPermissions(role),
      councilCode: toString(role.councilCode),
      description: toString(role.description),
      activeDirectoryGroupId: toString(role.activeDirectoryGroupId),
      activeDirectoryGroupName: toString(role.activeDirectoryGroupName),
    };
  },
};

export const addRoleFormSchema = z.object({
  name: z
    .string({ required_error: 'Role name is required' })
    .min(3, 'Minimum 3 characters required')
    .max(200, 'Maximum 200 characters supported'),
  status: z.string().optional(),
  privileges: z.string().array().optional(),
  councilCode: z.string().optional(),
  description: z
    .string({ required_error: 'Role description is required' })
    .min(3, 'Minimum 3 characters required')
    .max(500, 'Maximum 500 characters supported'),
  activeDirectoryGroupId: z.string().uuid({ message: 'Invalid uuid' }).optional(),
  activeDirectoryGroupName: z.string().optional(),
});

export type AddRoleFormValues = z.infer<typeof addRoleFormSchema>;

export const updateRoleFormSchema = addRoleFormSchema.extend({
  id: z.string(),
  revision: z.number(),
});

export type UpdateRoleFormValues = z.infer<typeof updateRoleFormSchema>;
