import { IApiClient } from 'services/api-client/ApiClient.types';
import axios, { AxiosInstance } from 'axios';
import { BUILD_AU_API_ENDPOINT, LICENCE_SERVER_API_AUTH_URL } from 'constants/configs';
import { Cradle } from 'services/serviceContainer.types';
import merge from 'lodash/merge';
import { ServiceError } from 'services/ServiceError';

export class ApiClient implements IApiClient {
  private readonly cognitoService: Cradle['cognitoService'];

  private authInstance: AxiosInstance | null = null;
  private protectedInstance: AxiosInstance | null = null;

  constructor(args: Pick<Cradle, 'cognitoService'>) {
    this.cognitoService = args.cognitoService;
  }

  get authApi(): AxiosInstance {
    if (this.authInstance) {
      return this.authInstance;
    }

    const instance = axios.create({
      baseURL: LICENCE_SERVER_API_AUTH_URL,
    });

    return instance;
  }

  get protectedApi(): AxiosInstance {
    if (this.protectedInstance) {
      return this.protectedInstance;
    }

    const instance = axios.create({
      baseURL: BUILD_AU_API_ENDPOINT,
    });

    // Include bearer token with every request
    instance.interceptors.request.use(async (config) => {
      const idToken = await this.cognitoService.getIdToken();

      const newConfig = merge({}, config, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return newConfig;
    });

    // Ever error should be service error
    instance.interceptors.response.use(
      (response) => response,
      async (e) => {
        // Always redirect to root if there is forbidden
        if (e.status === 403) {
          window.location.href = '/';
        }
        // Always throw ServiceError
        const error = ServiceError.createFromResponseError(e);
        return Promise.reject(error);
      },
    );
    return instance;
  }
}
