// Proxy access to import.meta.env to provide meaningful defaults

// Backend
export const BUILD_API_ENDPOINT: string = import.meta.env.REACT_APP_BUILD_API_ENDPOINT || '';
export const USE_MOCK_BACKEND = import.meta.env.REACT_APP_USE_MOCK_BACKEND === 'true';
export const BUILD_AU_API_ENDPOINT = import.meta.env.REACT_APP_BUILD_AU_API_BASE_URL || '';
export const LICENCE_SERVER_API_AUTH_URL = import.meta.env.REACT_APP_LICENCE_SERVER_API_AUTH_URL || '';

// Env
export const NODE_ENV: string = import.meta.env.NODE_ENV || 'development';
export const IS_DEV_ENV: boolean = NODE_ENV === 'development';
export const IS_TEST_ENV: boolean = NODE_ENV === 'test';
export const IS_PRODUCTION_ENV: boolean = NODE_ENV === 'production';

// AWS
export const AWS_COGNITO_REGION = import.meta.env.REACT_APP_AWS_COGNITO_REGION || '';
export const AWS_USER_POOLS_ID = import.meta.env.REACT_APP_AWS_USER_POOLS_ID || '';
export const AWS_USER_POOLS_WEB_CLIENT_ID = import.meta.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '';
export const AWS_COGNITO_OAUTH_DOMAIN = import.meta.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN || '';
export const AWS_COGNITO_OAUTH_BASE_URL = import.meta.env.REACT_APP_AWS_COGNITO_OAUTH_BASE_URL || '';
export const AWS_COGNITO_MOCK_USER_ACCOUNT = import.meta.env.REACT_APP_AWS_COGNITO_MOCK_USER_ACCOUNT || '';
export const AWS_COGNITO_MOCK_USER_PASSWORD = import.meta.env.REACT_APP_AWS_COGNITO_MOCK_USER_PASSWORD || '';
export const AWS_COGNITO_USE_MOCK = import.meta.env.REACT_APP_AWS_COGNITO_USE_MOCK === 'true';

// Timings
export const DATA_REFRESH_DEFAULT_INTERVAL = 60000;
export const DATA_REFRESH_POLLING_INTERVAL = 5000;

// Search
export const DEBOUNCE_SEARCH_IN_MS = 400;

// Defaults
export const DEFAULT_COUNTRY_CODE = 'AU'; // can use 'US' when that is an option in the CountrySelect

// Site Version
export const BUILD_SITE_VERSION: string = import.meta.env.REACT_APP_BUILD_AU_VERSION || 'Unknown';

// Features
export const USE_DASHBOARD = import.meta.env.REACT_APP_USE_DASHBOARD === 'true';
export const USE_TASKS_AND_SCHEDULING = import.meta.env.REACT_APP_USE_TASKS_AND_SCHEDULING === 'true';
export const USE_REPORTING = import.meta.env.REACT_APP_USE_REPORTING === 'true';
export const USE_TEAMS = import.meta.env.REACT_APP_USE_TEAMS === 'true';
export const USE_USERS_AND_PERMISSIONS = import.meta.env.REACT_APP_USE_USERS_AND_PERMISSIONS === 'true';
export const USE_CREATE_APPLICATION = import.meta.env.REACT_APP_USE_CREATE_APPLICATION === 'true';

// Defaults
export const DEFAULT_VISIBLE_USER_AVATARS = 10;
export const DEFAULT_VISIBLE_USER_ROLES = 3;

// Posthog
export const PUBLIC_POSTHOG_KEY = import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY || '';
export const PUBLIC_POSTHOG_HOST = import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST || '';

// Supported Mime Types
export enum SUPPORTED_MIME_TYPES {
  ApplicationPdf = 'application/pdf',
}

// Notification Provider
export const NOTIFICATION_TIMEOUT = 1500;

// Spacing
export const BLANK_SPACE = '\u00A0';
