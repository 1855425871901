import { combineReducers } from '@reduxjs/toolkit';
import { roleReducer } from 'store/domain-data/role/role';
import { applicationReducer } from 'store/domain-data/application/application';
import { assignmentReducer } from 'store/domain-data/assignment/assignment';
import { userReducer } from 'store/domain-data/user/user';
import { permissionReducer } from 'store/domain-data/permission/permission';
import { userAllocationReducer } from 'store/domain-data/user-allocation-relation/userAllocationRelation';

export const domainDataReducer = combineReducers({
  role: roleReducer,
  application: applicationReducer,
  assignment: assignmentReducer,
  permission: permissionReducer,
  user: userReducer,
  userAllocationRelation: userAllocationReducer,
});
