import { combineReducers } from '@reduxjs/toolkit';
import { authenticationReducer } from 'store/app-state/authentication/authentication';
import { uiReducer } from 'store/app-state/ui/ui';
import { loadingReducer } from 'store/app-state/loading/loading';
import { applicationSelectionBufferReducer } from 'store/app-state/application-selection-buffer/applicationSelectionBuffer';

export const appStateReducer = combineReducers({
  authentication: authenticationReducer,
  loading: loadingReducer,
  ui: uiReducer,
  applicationSelectionBuffer: applicationSelectionBufferReducer,
});
