import { useStoreDispatch, useStoreSelector } from 'store/hooks';
import { loginWithCachedState, selectAuthenticationState } from 'store/app-state/authentication/authentication';
import React from 'react';
import { fetchCurrentLoggedInUser } from 'store/domain-data/user/user';

export const useAuthenticationRoutine = () => {
  const dispatch = useStoreDispatch();
  const { authenticated } = useStoreSelector(selectAuthenticationState);

  // Automatically login if user has cached authentication state
  React.useEffect(() => {
    dispatch(loginWithCachedState());
  }, [dispatch]);

  // When authentication changes, update the current logged in user
  React.useEffect(() => {
    if (!authenticated) {
      return;
    }

    dispatch(fetchCurrentLoggedInUser());
  }, [authenticated, dispatch]);
};
