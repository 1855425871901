import React from 'react';
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY } from 'constants/configs';
import { PostHogProvider as OriginalPostHogProvider } from 'posthog-js/react';
// @ts-ignore
import posthog from 'posthog-js/dist/main';
import isEmpty from 'lodash/isEmpty';

type Props = {
  children: React.ReactNode;
};

const postHogOptions = {
  api_host: PUBLIC_POSTHOG_HOST,
  autocapture: false,
  capture_pageview: true,
  capture_pageleave: true,
};

const PostHogProvider: React.FC<Props> = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false);

  if (!initialized && !isEmpty(PUBLIC_POSTHOG_KEY)) {
    posthog.init(`${PUBLIC_POSTHOG_KEY}`, postHogOptions);
    setInitialized(true);
  }

  return <OriginalPostHogProvider client={posthog}>{children}</OriginalPostHogProvider>;
};

export default PostHogProvider;
