import { Cradle } from 'services/serviceContainer.types';
import { IApplicationData, IApplicationService } from 'services/application/ApplicationService.types';
import { ApplicationUtil, IApplicationEntity } from 'models/Application.model';
import { DocumentUtil } from 'models/Document.model';
import { NotificationUtil } from 'models/Notification.model';
import { ComplianceChecklistUtil } from 'models/ComplianceChecklist.model';
import toString from 'lodash/toString';
import get from 'lodash/get';
import { RFIsUtil } from 'models/RFI.model';
import { ReferralUtil } from 'models/Referral.model';
import { SiteVisitUtil } from 'models/SiteVisit.model';
import { ComplianceStatusUtil } from 'models/Compliance.model';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import { ApplicationCategoryUtil } from 'models/ApplicationCategory.model';
import { ApplicationTypeUtil } from 'models/ApplicationType.model';
import toInteger from 'lodash/toInteger';

export class ApplicationService implements IApplicationService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  /**
   * Create new application with the given data
   * @param newApplication The data for the new application
   * @returns A promise resolving to TRUE if the application was created, FALSE otherwise
   */
  public async createApplication(newApplication: IApplicationData): Promise<boolean> {
    const response = await this.apiClient.protectedApi.post<any>('applications', newApplication);
    return response.status === 201;
  }

  /**
   * Edit an existing application with the given data
   * @param applicationId The id of the application to retrieve data
   * @param editedApplication The edited data for the application
   * @returns A promise resolving to TRUE if the application edit was successful, FALSE otherwise
   */
  public async updateApplication(applicationId: string, editedApplication: IApplicationData): Promise<boolean> {
    const response = await this.apiClient.protectedApi.put<any>(
      `applications/${applicationId}/applicationData`,
      editedApplication,
    );
    return response.status === 201;
  }

  /**
   * Gets list of applications by dashboard type
   * @param applicationId The id of the application to retrieve data
   * @returns A promise resolving to an application's data
   */
  public async fetchApplicationData(applicationId: string): Promise<IApplicationData> {
    const response = await this.apiClient.protectedApi.get<IApplicationData>(
      `applications/${applicationId}/applicationData`,
    );
    return response.data;
  }

  /**
   * Get the details for the application with the given id
   * @param applicationId The application id to retrieve
   * @returns A promise resolving to an application's details
   */
  public async fetchUserApplicationById(applicationId: string) {
    const response = await this.apiClient.protectedApi.get(`applications/${applicationId}`);
    const application = get(response, 'data');
    return {
      application,
    };
  }

  /**
   * fetches list of user applications
   */
  public async fetchUserApplications() {
    const response = await this.apiClient.protectedApi.get(`applications`);
    const jsonArr = get(response.data, 'applications');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }
    return { applications: this.parseApplications(jsonArr) };
  }

  /**
   * fetches list of applications
   */
  public async fetchAllApplications() {
    const response = await this.apiClient.protectedApi.get('applications/all');

    const jsonArr = get(response.data, 'applications');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch child applications`));
    }
    return { applications: this.parseApplications(jsonArr) };
  }

  /**
   * fetches list of unallocated applications
   */
  public async fetchUnallocatedApplications() {
    const response = await this.apiClient.protectedApi.get(`applications/unallocated`);
    const jsonArr = get(response.data, 'applications');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }
    return { applications: this.parseApplications(jsonArr) };
  }

  /**
   * fetches list of allocated applications
   */
  public async fetchAllocatedApplications() {
    const response = await this.apiClient.protectedApi.get(`applications/allocated`);
    const jsonArr = get(response.data, 'applications');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }
    return { applications: this.parseApplications(jsonArr) };
  }

  private parseApplications(json: any[]): IApplicationEntity[] {
    const applications: IApplicationEntity[] = [];
    for (const application of json) {
      applications.push(this.parseApplication(application));
    }
    return applications;
  }

  private parseApplication(json: any): IApplicationEntity {
    const parsedApplication = {
      id: toString(get(json, 'id')),
      panId: toString(get(json, 'panId')),
      lodgementDate: toString(get(json, 'lodgementDate')),
      rfis: {
        sentCount: toInteger(get(json, 'rfis.sentCount')),
        receivedCount: toInteger(get(json, 'rfis.receivedCount')),
        summaryStatus: RFIsUtil.parseStatus(get(json, 'rfisStatus' || 'rfis.summaryStatus')),
        completedCount: toInteger(get(json, 'rfis.completedCount')),
        rfisCount: toInteger(get(json, 'rfis.rfisCount')),
      },
      status: ApplicationUtil.parseStatus(get(json, 'status')),
      zoning: toString(get(json, 'zoning')),
      hasAlert: Boolean(get(json, 'hasAlert')),
      documents: DocumentUtil.parseDocuments(get(json, 'documents')),
      ownerName: toString(get(json, 'ownerName')),
      referrals: {
        overdueCount: toInteger(get(json, 'referrals.overdueCount')),
        receivedCount: toInteger(get(json, 'referrals.receivedCount')),
        summaryStatus: ReferralUtil.parseStatus(get(json, 'referrals.summaryStatus')),
        referralsCount: toInteger(get(json, 'referrals.referralsCount')),
      },
      siteVisits: {
        nextDateTime: toString(get(json, 'siteVisits.nextDateTime')),
        summaryStatus: SiteVisitUtil.parseStatus(get(json, 'siteVisitsStatus' || 'siteVisits.summaryStatus')),
        completedCount: toInteger(get(json, 'siteVisits.completedCount')),
        siteVisitsCount: toInteger(get(json, 'siteVisits.siteVisitsCount')),
      },
      councilName: toString(get(json, 'councilName')),
      councilCode: toString(get(json, 'councilCode')),
      description: toString(get(json, 'description')),
      fullAddress: toString(get(json, 'fullAddress')),
      applicantName: toString(get(json, 'applicantName')),
      determination: toString(get(json, 'determination')),
      notifications: NotificationUtil.parseNotifications(get(json, 'notifications')),
      processingDays: toInteger(get(json, 'processingDays')),
      createdDateTime: toString(get(json, 'createdDateTime')),
      modifiedDateTime: toString(get(json, 'modifiedDateTime')),
      applicationReference: toString(get(json, 'applicationReference')),
      complianceChecklists: {
        summaryStatus: ComplianceStatusUtil.parseStatus(
          get(json, 'complianceStatus' || 'complianceChecklists.summaryStatus'),
        ),
        complianceChecklistsCount: toInteger(get(json, 'complianceChecklists.complianceChecklistsCount')),
        completedCount: toInteger(get(json, 'complianceChecklists.completedCount')),
        checklists: ComplianceChecklistUtil.parseComplianceChecklists(get(json, 'complianceChecklists.checklists')),
      },
      applicationCategory: ApplicationCategoryUtil.parse(get(json, 'applicationCategory')),
      applicationType: ApplicationTypeUtil.parse(get(json, 'applicationType')),
      allocation: {
        effectiveDateTime: toString(get(json, 'allocation.createdDateTime')),
        userId: toString(get(json, 'userId')) || toString(get(json, 'allocation.userId')),
        comment: toString(get(json, 'allocation.comment')),
      },
    };

    return parsedApplication;
  }
}

export default ApplicationService;
