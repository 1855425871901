import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { ApplicationStatus, IApplicationEntity } from 'models/Application.model';
import { USE_MOCK_BACKEND } from 'constants/configs';
import toNumber from 'lodash/toNumber';
import { serviceContainer } from 'services/serviceContainer';
import { RootState } from 'store/types';
import { createDeepEqualSelector } from 'store/utils';
import { selectAssignmentEntityIds, upsertAssignments } from 'store/domain-data/assignment/assignment';
import isEmpty from 'lodash/isEmpty';

// Entity Adapter
const entityAdapter = createEntityAdapter<IApplicationEntity>({
  sortComparer: (a, b) =>
    USE_MOCK_BACKEND ? toNumber(a.id) - toNumber(b.id) : a.applicantName.localeCompare(b.applicantName),
});

// Actions
const upsertApplications = createAction<IApplicationEntity[]>('domainData/applications/upsertApplications');
const upsertApplication = createAction<IApplicationEntity>('domainData/applications/upsertApplication');

// Thunks
export const fetchUserApplications = createAsyncThunk(
  'domainData/applications/fetchUserApplications',
  async (_, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { applications } = await applicationService.fetchUserApplications();
    thunkAPI.dispatch(upsertApplications(applications));
    thunkAPI.dispatch(
      upsertAssignments(
        applications.map((application) => {
          return {
            id: application.id,
          };
        }),
      ),
    );
    return applications;
  },
);

export const fetchUserApplicationById = createAsyncThunk(
  'domainData/applications/fetchUserApplicationById',
  async (applicationId: string, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { application } = await applicationService.fetchUserApplicationById(applicationId);
    thunkAPI.dispatch(upsertApplication(application));
    return application;
  },
);

export const fetchAllApplications = createAsyncThunk(
  'domainData/applications/fetchAllApplications',
  async (_, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { applications } = await applicationService.fetchAllApplications();

    thunkAPI.dispatch(upsertApplications(applications));

    return applications;
  },
);

export const fetchUnallocatedApplications = createAsyncThunk(
  'domainData/applications/fetchUnallocatedApplications',
  async (_, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { applications } = await applicationService.fetchUnallocatedApplications();
    thunkAPI.dispatch(upsertApplications(applications));
    return applications;
  },
);

export const fetchAllocatedApplications = createAsyncThunk(
  'domainData/applications/fetchAllocatedApplications',
  async (_, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { applications } = await applicationService.fetchAllocatedApplications();
    thunkAPI.dispatch(upsertApplications(applications));
    return applications;
  },
);

// Reducer
const initialState = entityAdapter.getInitialState();

export const applicationReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertApplications, entityAdapter.upsertMany).addCase(upsertApplication, entityAdapter.upsertOne);
});

export const {
  selectById: selectApplicationEntityById,
  selectIds: selectApplicationEntityIds,
  selectEntities: selectApplicationEntities,
  selectAll: selectAllApplicationEntities,
  selectTotal: selectTotalApplicationEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.application);

export const selectUnAllocatedApplications = createDeepEqualSelector(
  [selectAllApplicationEntities],
  (allApplications) => {
    const unAllocatedApplications = allApplications.filter((application) => isEmpty(application.allocation?.userId));
    return unAllocatedApplications;
  },
);

export const selectAllocatedApplications = createDeepEqualSelector(
  [selectAllApplicationEntities],
  (allApplications) => {
    const allocatedApplications = allApplications.filter((application) => !isEmpty(application.allocation?.userId));
    return allocatedApplications;
  },
);

export const selectCurrentUserAssignedApplications = createDeepEqualSelector(
  [selectAllApplicationEntities, selectAssignmentEntityIds],
  (allApplications, assignments) => {
    const currentAssignments = allApplications.filter((application) => assignments.includes(application.id));
    const assignedApplications = currentAssignments.filter(
      (application) => application.status !== (ApplicationStatus.Unassigned || ApplicationStatus.Unknown),
    );
    return assignedApplications;
  },
);
