import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import {
  ConfirmResetPasswordArgs,
  LoginArgs,
  LoginWithRedirectArgs,
  ResetPasswordArgs,
} from 'services/cognito/CognitoService.types';
import { createDeepEqualSelector } from 'store/utils';

// Type

type AuthenticationState = {
  authenticating: boolean;
  authenticated: boolean;
  emailVerified: boolean;
  federatedLogin: boolean;
  userUniqueId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPermissions: string[];
};

// Actions & Thunks
export const getIsDomainFederated = createAsyncThunk(
  'appState/authentication/getIsDomainFederated',
  (domain: string) => {
    return serviceContainer.cradle.federationService.getIsUserFederated(domain);
  },
);

export const loginWithCachedState = createAsyncThunk('appState/authentication/loginWithCachedState', () => {
  return serviceContainer.cradle.cognitoService.signInWithCachedState();
});

export const loginWithCredentials = createAsyncThunk(
  'appState/authentication/loginWithCredentials',
  async (args: LoginArgs, thunkAPI) => {
    const signIn = await serviceContainer.cradle.cognitoService.signInWithCredentials(args);
    thunkAPI.dispatch(loginWithCachedState());
    return signIn;
  },
);

export const loginWithRedirect = createAsyncThunk(
  'appState/authentication/loginWithRedirect',
  (args: LoginWithRedirectArgs) => {
    return serviceContainer.cradle.cognitoService.signInWithRedirectUrl(args);
  },
);

export const signOut = createAsyncThunk('appState/authentication/signOut', () => {
  serviceContainer.cradle.cognitoService.signOut();
  localStorage.clear();
  window.location.href = '/';
});

export const resetPassword = createAsyncThunk('appState/authentication/resetPassword', (args: ResetPasswordArgs) => {
  return serviceContainer.cradle.cognitoService.resetPassword(args);
});

export const confirmResetPassword = createAsyncThunk(
  'appState/authentication/confirmResetPassword',
  (args: ConfirmResetPasswordArgs) => {
    return serviceContainer.cradle.cognitoService.confirmResetPassword(args);
  },
);

// Reducer

export const defaultAuthenticationState: AuthenticationState = {
  authenticating: false,
  authenticated: false,
  emailVerified: false,
  federatedLogin: false,
  userUniqueId: '',
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPermissions: [],
};

export const authenticationReducer = createReducer<AuthenticationState>(defaultAuthenticationState, (builder) => {
  builder
    .addCase(getIsDomainFederated.pending, (state, action) => {
      state.federatedLogin = false;
    })
    .addCase(getIsDomainFederated.fulfilled, (state, action) => {
      state.federatedLogin = action.payload;
    })
    .addCase(getIsDomainFederated.rejected, (state, action) => {
      state.federatedLogin = false;
    })
    .addCase(loginWithCachedState.pending, (state, action) => {
      state.authenticating = true;
    })
    .addCase(loginWithCachedState.fulfilled, (state, action) => {
      state.authenticating = false;
      state.authenticated = true;
      state.emailVerified = action.payload.emailVerified;
      state.userEmail = action.payload.userEmail;
      state.userPermissions = action.payload.userPermissions;
      state.userFirstName = action.payload.userFirstName;
      state.userLastName = action.payload.userLastName;
      state.userUniqueId = action.payload.userUniqueId;
    })
    .addCase(loginWithCachedState.rejected, (state, action) => {
      state.authenticating = false;
    })
    .addCase(loginWithCredentials.pending, (state, action) => {
      state.authenticating = true;
    })
    .addCase(loginWithCredentials.fulfilled, (state, action) => {
      state.authenticating = false;
      state.authenticated = true;
      state.emailVerified = action.payload.emailVerified;
      state.userEmail = action.payload.userEmail;
      state.userPermissions = action.payload.userPermissions;
      state.userFirstName = action.payload.userFirstName;
      state.userLastName = action.payload.userLastName;
      state.userUniqueId = action.payload.userUniqueId;
    })
    .addCase(loginWithCredentials.rejected, (state, action) => {
      state.authenticating = false;
    })
    .addCase(loginWithRedirect.pending, (state, action) => {
      state.authenticating = true;
    })
    .addCase(loginWithRedirect.fulfilled, (state, action) => {
      state.authenticating = false;
      state.authenticated = true;
    });
});

// Selectors

export const selectAuthenticationState = (state: RootState) => {
  return state.appState.authentication;
};

export const selectIsAuthenticating = createDeepEqualSelector(
  [selectAuthenticationState],
  (state) => state.authenticating,
);
