import { IProfileService } from 'services/profile/ProfileService.types';
import { Cradle } from 'services/serviceContainer.types';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import { IUserEntity, UserUtil } from 'models/User.model';

export class ProfileService implements IProfileService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchCurrentUserInfo(): Promise<IUserEntity> {
    try {
      const response = await this.apiClient.protectedApi.get(`/profile`);
      const json = response.data;
      json.emailAddress = 'leo.singh@objective.com';
      const user = UserUtil.parseUser(json, true);
      return user;
    } catch (e: any) {
      throw new ServiceError(ServiceErrorCode.ServerError, e.message);
    }
  }
}
