// Types
import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { createDeepEqualSelector } from 'store/utils';

type ApplicationSelectionBuffer = {
  selectedApplicationId: string;
  selectedApplicationIds: string[];
  activeSelectedApplicationId: string;
  activeSelectedAssessmentOfficerId: string;
};

// Actions
export const setSelectedApplicationSelectionBuffer = createAction<string>(
  'appState/applicationSelectionBuffer/setSelectedApplicationSelectionBuffer',
);

export const setSelectedApplicationsSelectionBuffer = createAction<string[]>(
  'appState/applicationSelectionBuffer/setSelectedApplicationsSelectionBuffer',
);

export const setActiveApplicationId = createAction<string>(
  'appState/applicationSelectionBuffer/setActiveApplicationId',
);

// Reducer

export const initialState: ApplicationSelectionBuffer = {
  selectedApplicationId: '',
  selectedApplicationIds: [],
  activeSelectedApplicationId: '',
  activeSelectedAssessmentOfficerId: '',
};

export const applicationSelectionBufferReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectedApplicationSelectionBuffer, (draft, action) => {
      draft.selectedApplicationId = action.payload;
    })
    .addCase(setSelectedApplicationsSelectionBuffer, (draft, action) => {
      draft.selectedApplicationIds = action.payload;
    })
    .addCase(setActiveApplicationId, (draft, action) => {
      draft.activeSelectedApplicationId = action.payload;
    });
});

// Selectors

const selectApplicationSelectionBufferState = (state: RootState) => state.appState.applicationSelectionBuffer;

export const selectSelectedApplicationId = createDeepEqualSelector(
  [selectApplicationSelectionBufferState],
  (state: ApplicationSelectionBuffer) => {
    return state.selectedApplicationId;
  },
);

export const selectSelectedApplicationIds = createDeepEqualSelector(
  [selectApplicationSelectionBufferState],
  (state: ApplicationSelectionBuffer) => {
    return state.selectedApplicationIds;
  },
);
