import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { AddUserFormValues, IUserEntity, UpdateUserFormValues, UpdateUserRolesFormValues } from 'models/User.model';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import { createDeepEqualSelector } from 'store/utils';
import { fetchRoles } from 'store/domain-data/role/role';
import { PermissionName } from 'models/Permission.model';
import toLower from 'lodash/toLower';

// Entity Adapter
const entityAdapter = createEntityAdapter<IUserEntity>();

// Actions
const upsertUsers = createAction<IUserEntity[]>('domainData/user/upsertUsers');
const upsertUser = createAction<IUserEntity>('domainData/user/upsertUser');

// Thunks
export const fetchUsers = createAsyncThunk('domainData/user/fetchUsers', async (_, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { users } = await userService.fetchUsers();
  thunkAPI.dispatch(upsertUsers(users));
  return users;
});

export const fetchUserById = createAsyncThunk('domainData/user/fetchUser', async (userId: string, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { user } = await userService.fetchUserById(userId);
  thunkAPI.dispatch(upsertUser(user));
  return user;
});

export const addNewUser = createAsyncThunk('domainData/user/updateUser', async (args: AddUserFormValues, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { id } = await userService.addNewUser(args);
  const { user } = await userService.fetchUserById(id);
  thunkAPI.dispatch(fetchRoles());
  return user;
});

export const updateUser = createAsyncThunk(
  'domainData/user/updateUser',
  async (args: UpdateUserFormValues, thunkAPI) => {
    const userService = serviceContainer.cradle.userService;
    const user = await userService.updateUser(args);
    thunkAPI.dispatch(upsertUser(user));
    return user;
  },
);

export const updateUserRoles = createAsyncThunk(
  'domainData/user/updateUserRoles',
  async (args: UpdateUserRolesFormValues, thunkAPI) => {
    const userService = serviceContainer.cradle.userService;
    const user = await userService.updateUserRoles(args);
    thunkAPI.dispatch(upsertUser(user));
    thunkAPI.dispatch(fetchRoles());
    return user;
  },
);

export const fetchCurrentLoggedInUser = createAsyncThunk(
  'domainData/user/fetchCurrentLoggedInUser',
  async (_, thunkAPI) => {
    const profileService = serviceContainer.cradle.profileService;
    const cognitoService = serviceContainer.cradle.cognitoService;

    const cognitoUser = await cognitoService.signInWithCachedState();

    const userInfo = await profileService.fetchCurrentUserInfo();
    userInfo.emailAddress = cognitoUser.userEmail;

    thunkAPI.dispatch(upsertUser(userInfo));
    return userInfo;
  },
);

// Reducer
const initialState = entityAdapter.getInitialState();

export const userReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertUsers, entityAdapter.upsertMany).addCase(upsertUser, entityAdapter.upsertOne);
});

// Selectors
export const {
  selectById: selectUserEntityById,
  selectIds: selectUserEntityIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUserEntities,
  selectTotal: selectTotalUserEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.user);

export const selectUserEntityForAuthenticatedUser = createDeepEqualSelector(
  [selectAllUserEntities, (state: RootState) => state.appState.authentication.userEmail],
  (entities, userUniqueEmail) => entities.find((entity) => toLower(entity.emailAddress) === toLower(userUniqueEmail)),
);

export const selectUserRoleIds = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user) {
    return [];
  }
  return user.roleIds;
});

// User privileges selectors
const READ_USERS_PRIVILEGES = [PermissionName.ReadUser, PermissionName.AdministerUser];
const CREATE_USERS_PRIVILEGES = [PermissionName.CreateUser, PermissionName.AdministerUser];
const UPDATE_USERS_PRIVILEGES = [PermissionName.UpdateUser, PermissionName.AdministerUser];

export const selectCanReadUsers = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return READ_USERS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanCreateUsers = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return CREATE_USERS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanUpdateUsers = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return UPDATE_USERS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

// Application privileges selectors
const READ_APPLICATIONS_PRIVILEGES = [PermissionName.ReadApplication, PermissionName.AdministerApplication];
const CREATE_APPLICATIONS_PRIVILEGES = [PermissionName.CreateApplication, PermissionName.AdministerApplication];
const UPDATE_APPLICATIONS_PRIVILEGES = [PermissionName.UpdateApplication, PermissionName.AdministerApplication];

export const selectCanReadApplications = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return READ_APPLICATIONS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanCreateApplications = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return CREATE_APPLICATIONS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanUpdateApplications = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return UPDATE_APPLICATIONS_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

// Role privileges selectors
const READ_ROLES_PRIVILEGES = [PermissionName.ReadRole, PermissionName.AdministerRole];
const CREATE_ROLES_PRIVILEGES = [PermissionName.CreateRole, PermissionName.AdministerRole];
const UPDATE_ROLES_PRIVILEGES = [PermissionName.UpdateRole, PermissionName.AdministerRole];

export const selectCanReadRoles = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return READ_ROLES_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanCreateRoles = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return CREATE_ROLES_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});

export const selectCanUpdateRoles = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user || !user.privileges) {
    return false;
  }
  return UPDATE_ROLES_PRIVILEGES.some((privilegeName) => user.privileges?.includes(privilegeName));
});
