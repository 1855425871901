export enum ApplicationType {
  DA = 'DA',
  Modified = 'Modified',
  Review = 'Review',
  Unknown = 'Unknown',
}

export const ApplicationTypeUtil = {
  parse(type: string) {
    for (const value of Object.values(ApplicationType)) {
      if (type === value) {
        return value;
      }
    }
    return ApplicationType.Unknown;
  },

  parseDisplayValue(type: string) {
    switch (type) {
      case ApplicationType.DA:
        return 'Development';
      case ApplicationType.Modified:
        return 'Modification application';
      case ApplicationType.Review:
        return 'Review application';
    }
  },
};
