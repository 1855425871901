import { Cradle } from 'services/serviceContainer.types';
import get from 'lodash/get';
import toString from 'lodash/toString';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import {
  AddUserFormValues,
  IUserEntity,
  UpdateUserFormValues,
  UpdateUserRolesFormValues,
  UserUtil,
} from 'models/User.model';
import { IUserService } from 'src/services/user/UserService.types';
import { ObjectUtil } from 'utils/ObjectUtil';

export class UserService implements IUserService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchUsers(): Promise<{
    users: IUserEntity[];
  }> {
    const response = await this.apiClient.protectedApi.get(`/users`);

    const jsonArr = get(response.data, 'users');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch users`));
    }

    const users = UserUtil.parseUsers(jsonArr);

    return {
      users,
    };
  }

  public async fetchUserById(userId: string) {
    const response = await this.apiClient.protectedApi.get(`/users/${userId}`);
    const json = response.data;
    const user = UserUtil.parseUser(json);
    return {
      user,
    };
  }

  public async addNewUser(args: AddUserFormValues) {
    const response = await this.apiClient.protectedApi.post('/users', args);
    const id = toString(get(response.data, 'id'));
    return { id };
  }

  public async updateUser(args: UpdateUserFormValues): Promise<IUserEntity> {
    const { roleIds, ...restArgs } = args;
    const response = await this.apiClient.protectedApi.put(
      `/users/${args.id}`,
      ObjectUtil.deleteEmptyObjects({ ...restArgs, roles: [...roleIds] }),
    );
    const json = response.data;
    const user = UserUtil.parseUser(json);
    return user;
  }

  public async updateUserRoles(args: UpdateUserRolesFormValues): Promise<IUserEntity> {
    try {
      const response = await this.apiClient.protectedApi.patch(`/users/${args.userId}/roles`, args);
      const json = response.data;
      const user = UserUtil.parseUser(json);
      return user;
    } catch (e: any) {
      const isUserWithOnlyRole = e.message.includes('User can not exist without role');
      const message = isUserWithOnlyRole
        ? this.i18n.t('User cannot exist without role')
        : this.i18n.t('Error updating user roles');
      throw new ServiceError(ServiceErrorCode.ServerError, message);
    }
  }
}
