import { z } from 'zod';

export enum PermissionName {
  CreateApplication = 'CreateApplication',
  ReadApplication = 'ReadApplication',
  UpdateApplication = 'UpdateApplication',
  DeleteApplication = 'DeleteApplication',
  AdministerApplication = 'AdministerApplication',
  CreateApplicationDocument = 'CreateApplicationDocument',
  ReadApplicationDocument = 'ReadApplicationDocument',
  UpdateApplicationDocument = 'UpdateApplicationDocument',
  DeleteApplicationDocument = 'DeleteApplicationDocument',
  AdministerApplicationDocument = 'AdministerApplicationDocument',
  CreateAssessment = 'CreateAssessment',
  AdministerAssessment = 'AdministerAssessment',
  CreateAssignment = 'CreateAssignment',
  ReadAssignment = 'ReadAssignment',
  UpdateAssignment = 'UpdateAssignment',
  DeleteAssignment = 'DeleteAssignment',
  AdministerAssignment = 'AdministerAssignment',
  CreateChecklist = 'CreateChecklist',
  ReadChecklist = 'ReadChecklist',
  UpdateChecklist = 'UpdateChecklist',
  DeleteChecklist = 'DeleteChecklist',
  AdministerChecklist = 'AdministerChecklist',
  CreateCompliance = 'CreateCompliance',
  AdministerCompliance = 'AdministerCompliance',
  CreateUser = 'CreateUser',
  ReadUser = 'ReadUser',
  UpdateUser = 'UpdateUser',
  AdministerUser = 'AdministerUser',
  CreateRole = 'CreateRole',
  ReadRole = 'ReadRole',
  UpdateRole = 'UpdateRole',
  AdministerRole = 'AdministerRole',
  Unknown = 'Unknown',
}

export enum PermissionStatus {
  Granted = 'Granted',
  Denied = 'Denied',
  Unknown = 'Unknown',
}

export const permissionSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  status: z.string(),
  resourceType: z.string(),
});

export type IPermissionEntity = z.infer<typeof permissionSchema>;

export const PermissionUtil = {
  parseName(name: string) {
    for (const value of Object.values(PermissionName)) {
      if (value === name) {
        return value;
      }
    }
    return PermissionName.Unknown;
  },

  parseStatus(status: string) {
    for (const value of Object.values(PermissionStatus)) {
      if (value === status) {
        return value;
      }
    }
    return PermissionStatus.Unknown;
  },
};
