import React from 'react';
import ReactDOM from 'react-dom/client';
import { serviceContainer } from 'services/serviceContainer';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import App from 'components/App/App';
import { USE_MOCK_BACKEND } from 'constants/configs';
import 'styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

let initMockServer: () => Promise<void>;

initMockServer = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  const { worker } = await import('mock-server/env/browser');
  await worker.start();
};

const prepare = async () => {
  // Mock backend server in browser while development
  if (USE_MOCK_BACKEND && initMockServer) {
    await initMockServer();
  }

  // Initialize global i18n instance
  serviceContainer.cradle.i18nInitializer.initI18n();
  // Initialize aws amplify
  serviceContainer.cradle.cognitoService.initAWSAmplify();
};

prepare().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <React.Suspense fallback={<div />}>
        <Provider store={store}>
          <App />
        </Provider>
      </React.Suspense>
    </React.StrictMode>,
  );
});
