export enum ApplicationCategory {
  Residential = 'Residential',
  Commercial = 'Commercial',
  Unknown = 'Unknown',
}

export const ApplicationCategoryUtil = {
  parse(category: string) {
    for (const value of Object.values(ApplicationCategory)) {
      if (category === value) {
        return value;
      }
    }
    return ApplicationCategory.Unknown;
  },
};
