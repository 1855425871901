import { z } from 'zod';
import { notificationSchema } from 'models/Notification.model';
import { documentSchema } from 'models/Document.model';
import { complianceChecklistSchema } from 'models/ComplianceChecklist.model';
import { RFIStatus } from 'models/RFI.model';
import { ReferralStatus } from 'models/Referral.model';
import { ApplicationCategory } from 'models/ApplicationCategory.model';
import { ApplicationType } from 'models/ApplicationType.model';

export enum ApplicationStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Withdrawn = 'Withdrawn',
  Unassigned = 'Unassigned',
  AwaitingApproval = 'AwaitingApproval',
  Completed = 'Completed',
  Unknown = 'Unknown',
}

export enum ApplicationTab {
  Unallocated = 'Unallocated',
  Allocated = 'Allocated',
  Completed = 'Completed',
  TeamMembers = 'Team members',
  Active = 'Active',
  AwaitingApproval = 'AwaitingApproval',
  Determined = 'Determined',
  Withdrawn = 'Withdrawn',
}

export const ApplicationUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(ApplicationStatus)) {
      if (status === value) {
        return value;
      }
    }
    return ApplicationStatus.Unknown;
  },
};

//determination need to include
const applicationSchema = z.object({
  id: z.string(),
  allocation: z
    .object({
      effectiveDateTime: z.string(),
      userId: z.string(),
      comment: z.string().optional(),
    })
    .optional(),
  panId: z.string(),
  lodgementDate: z.string(),
  rfis: z.object({
    sentCount: z.number(),
    receivedCount: z.number(),
    summaryStatus: z.nativeEnum(RFIStatus),
    completedCount: z.number(),
    rfisCount: z.number(),
  }),
  status: z.nativeEnum(ApplicationStatus),
  zoning: z.string(),
  hasAlert: z.boolean(),
  documents: documentSchema.array(),
  ownerName: z.string(),
  referrals: z.object({
    overdueCount: z.number(),
    receivedCount: z.number(),
    summaryStatus: z.nativeEnum(ReferralStatus),
    referralsCount: z.number(),
  }),
  siteVisits: z.object({
    nextDateTime: z.string(),
    summaryStatus: z.string(),
    completedCount: z.number(),
    siteVisitsCount: z.number(),
  }),
  councilCode: z.string(),
  description: z.string(),
  fullAddress: z.string(),
  applicantName: z.string(),
  determination: z.string(),
  notifications: notificationSchema.array(),
  processingDays: z.number(),
  createdDateTime: z.string(),
  modifiedDateTime: z.string(),
  applicationReference: z.string(),
  complianceChecklists: z.object({
    checklists: complianceChecklistSchema.array(),
    summaryStatus: z.string(),
    completedCount: z.number(),
    complianceChecklistsCount: z.number(),
  }),
  applicationCategory: z.nativeEnum(ApplicationCategory),
  applicationType: z.nativeEnum(ApplicationType),
});

export type IApplicationEntity = z.infer<typeof applicationSchema>;
