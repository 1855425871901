import * as awilix from 'awilix';
import { asClass, asFunction, asValue } from 'awilix';
import { developmentLogger } from 'services/logger/developmentLogger';
import { productionLogger } from 'services/logger/productionLogger';
import { AWS_COGNITO_USE_MOCK, IS_DEV_ENV, IS_TEST_ENV } from 'constants/configs';
import { Cradle } from 'services/serviceContainer.types';
import { I18nInitializer } from 'services/i18n/I18nInitializer';
import { makeI18n } from 'services/i18n/makeI18n';
import { I18nInitializerMock } from 'services/i18n/I18nInitializer.mock';
import ApplicationService from 'services/application/ApplicationService';
import { ApiClient } from 'services/api-client/ApiClient';
import { CognitoService } from 'services/cognito/CognitoService';
import { FederationService } from 'services/federation/FederationService';
import { ICognitoService } from 'services/cognito/CognitoService.types';
import { RoleService } from 'services/role/RoleService';
import { ComplianceService } from 'services/compliance/ComplianceService';
import { UserService } from 'services/user/UserService';
import { PermissionService } from 'services/permission/PermissionService';
import { ProfileService } from 'services/profile/ProfileService';

const serviceContainer = awilix.createContainer<Cradle>({
  injectionMode: awilix.InjectionMode.PROXY,
});

// Register default services
serviceContainer.register({
  cognitoService: asClass(CognitoService).singleton(),
  i18n: asFunction(makeI18n).singleton(),
  i18nInitializer: asClass(I18nInitializer).singleton(),
  federationService: asClass(FederationService).singleton(),
  permissionService: asClass(PermissionService).singleton(),
  roleService: asClass(RoleService).singleton(),
  userService: asClass(UserService).singleton(),
  profileService: asClass(ProfileService).singleton(),
  applicationService: asClass(ApplicationService).singleton(),
  complianceService: asClass(ComplianceService).singleton(),
  logger: asValue(productionLogger),
  apiClient: asClass(ApiClient).singleton(),
});

// Register services on test env
if (IS_TEST_ENV) {
  serviceContainer.register({
    i18nInitializer: asClass(I18nInitializerMock).singleton(),
  });

  if (AWS_COGNITO_USE_MOCK) {
    const { CognitoServiceMock } = require('services/cognito/CognitoService.mock');
    serviceContainer.register({
      cognitoService: asClass<ICognitoService>(CognitoServiceMock).singleton(),
    });
  }
}

// Register services on development env
if (IS_DEV_ENV) {
  serviceContainer.register({
    logger: asValue(developmentLogger),
  });

  if (AWS_COGNITO_USE_MOCK) {
    const { CognitoServiceMock } = require('services/cognito/CognitoService.mock');
    serviceContainer.register({
      cognitoService: asClass<ICognitoService>(CognitoServiceMock).singleton(),
    });
  }
}

export { serviceContainer };
