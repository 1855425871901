export type IUserAllocationRelation = {
  userId: string;
  allocationId: string;
};

export const UserAllocationRelationUtil = {
  generateEntityKey: (relation: Pick<IUserAllocationRelation, 'userId' | 'allocationId'>) => {
    return `userId:${relation.userId}|allocation:${relation.allocationId}}`;
  },
};
