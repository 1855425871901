// This is a join table between Application and Document
import { createAction, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { IUserAllocationRelation, UserAllocationRelationUtil } from 'models/UserAllocationRelation.model';
import { RootState } from 'store/types';

const entityAdapter = createEntityAdapter({
  selectId: (entity: IUserAllocationRelation) => UserAllocationRelationUtil.generateEntityKey(entity),
});

export const upsertUserAllocationRelation = createAction<IUserAllocationRelation>(
  'domainData/userAllocationRelation/upsertUserAllocationRelation',
);

export const userAllocationReducer = createReducer(entityAdapter.getInitialState(), (builder) =>
  builder.addCase(upsertUserAllocationRelation, (draft, action) => {
    entityAdapter.upsertOne(draft, action.payload);
  }),
);

// Selectors
export const {
  selectById: selectUserAllocationRelationEntityById,
  selectIds: selectUserAllocationRelationEntityIds,
  selectEntities: selectUserAllocationRelationEntities,
  selectAll: selectAllUserAllocationRelationEntities,
  selectTotal: selectTotalUserAllocationRelationEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.userAllocationRelation);
