export enum RFIStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  AwaitingResponse = 'AwaitingResponse',
  AwaitingApproval = 'AwaitingApproval',
  Completed = 'Completed',
}

// A mapping of enum values to display values
const statusDisplayMap: { [key in RFIStatus]: string } = {
  [RFIStatus.NotStarted]: 'To do',
  [RFIStatus.InProgress]: 'In Progress',
  [RFIStatus.AwaitingResponse]: 'Awaiting Response',
  [RFIStatus.AwaitingApproval]: 'Awaiting Approval',
  [RFIStatus.Completed]: 'Completed',
};

export const RFIsUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(RFIStatus)) {
      if (status === value) {
        return value;
      }
    }
    return RFIStatus.NotStarted;
  },

  getDisplayName(status: string) {
    return statusDisplayMap[this.parseStatus(status)];
  },

  getStatusColor(status: string) {
    switch (status) {
      case RFIStatus.NotStarted:
        return;
      case RFIStatus.InProgress:
        return 'yellow';
      case RFIStatus.Completed:
        return 'green';
      case RFIStatus.AwaitingResponse:
        return 'mint'; // to be decided
      case RFIStatus.AwaitingApproval:
        return 'pink'; // to be decided
    }
  },
};
