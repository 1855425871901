export const ObjectUtil = {
  deleteEmptyObjects(obj: any) {
    for (const key in obj) {
      if (
        obj[key] === null || // Null value
        obj[key] === '' || // Empty string
        obj[key] === undefined || // Undefined
        Number.isNaN(obj[key]) // NaN value
      ) {
        delete obj[key];
      }
    }
    return obj;
  },
};
